import type { Environment, DemoEnvironment } from '@change-corgi/config/environments';

type TypesenseNode = {
	host: string;
	protocol: string;
	port: number;
	path: string;
};

export type TypensenseConfigProps = {
	apiKey: string;
	nearestNode?: TypesenseNode;
	nodes: TypesenseNode[];
};

// deepcode ignore HardcodedNonCryptoSecret: client-side token that is restricted to our domains.
const demoApiKey =
	'ZzlJL0Y0Z2YzWlpKeFlBL3ZuQUI5NERCaS90UmpWSzFoelJsM0NBRHFzYz1TVFc2eyJmaWx0ZXJfYnkiOiJkaXNjb3ZlcmFibGU6dHJ1ZSJ9';

export const typesenseConfig: Readonly<Record<Environment | DemoEnvironment, TypensenseConfigProps>> = {
	staging: {
		// deepcode ignore HardcodedNonCryptoSecret: client-side token that is restricted to our domains.
		apiKey:
			'bW15VG9DRWNjcktsV3A5WVJYb0NaMzR3K1BkblVyaS9JSms4T1ZPTzByQT15YWFyeyJmaWx0ZXJfYnkiOiJkaXNjb3ZlcmFibGU6dHJ1ZSJ9',
		nearestNode: {
			host: 'www.staging-change.org',
			protocol: 'https',
			port: 443,
			path: '/ts',
		},
		nodes: [
			{
				host: 'www.staging-change.org',
				protocol: 'https',
				port: 443,
				path: '/ts-0',
			},
			{
				host: 'www.staging-change.org',
				protocol: 'https',
				port: 443,
				path: '/ts-1',
			},
			{
				host: 'www.staging-change.org',
				protocol: 'https',
				port: 443,
				path: '/ts-2',
			},
		],
	},
	production: {
		// deepcode ignore HardcodedNonCryptoSecret: client-side token that is restricted to our domains.
		apiKey:
			'bThBakpSK1ZGaWtYNkVyMU5ZVTJIcGVxbkFmWlFuYWpCcHlWNVQ3UDVPMD1Tc0lweyJmaWx0ZXJfYnkiOiJkaXNjb3ZlcmFibGU6dHJ1ZSJ9',
		nearestNode: {
			host: 'www.change.org',
			protocol: 'https',
			port: 443,
			path: '/ts',
		},
		nodes: [
			{
				host: 'www.change.org',
				protocol: 'https',
				port: 443,
				path: '/ts-0',
			},
			{
				host: 'www.change.org',
				protocol: 'https',
				port: 443,
				path: '/ts-1',
			},
			{
				host: 'www.change.org',
				protocol: 'https',
				port: 443,
				path: '/ts-2',
			},
		],
	},
	cyan: {
		apiKey: demoApiKey,
		nearestNode: {
			host: 'www.cyan.changeeng.org',
			protocol: 'https',
			port: 443,
			path: '/ts',
		},
		nodes: [
			{
				host: 'www.cyan.changeeng.org',
				protocol: 'https',
				port: 443,
				path: '/ts-0',
			},
			{
				host: 'www.cyan.changeeng.org',
				protocol: 'https',
				port: 443,
				path: '/ts-1',
			},
			{
				host: 'www.cyan.changeeng.org',
				protocol: 'https',
				port: 443,
				path: '/ts-2',
			},
		],
	},
	jade: {
		apiKey: demoApiKey,
		nearestNode: {
			host: 'www.jade.changeeng.org',
			protocol: 'https',
			port: 443,
			path: '/ts',
		},
		nodes: [
			{
				host: 'www.jade.changeeng.org',
				protocol: 'https',
				port: 443,
				path: '/ts-0',
			},
			{
				host: 'www.jade.changeeng.org',
				protocol: 'https',
				port: 443,
				path: '/ts-1',
			},
			{
				host: 'www.jade.changeeng.org',
				protocol: 'https',
				port: 443,
				path: '/ts-2',
			},
		],
	},
	noir: {
		apiKey: demoApiKey,
		nearestNode: {
			host: 'www.noir.changeeng.org',
			protocol: 'https',
			port: 443,
			path: '/ts',
		},
		nodes: [
			{
				host: 'www.noir.changeeng.org',
				protocol: 'https',
				port: 443,
				path: '/ts-0',
			},
			{
				host: 'www.noir.changeeng.org',
				protocol: 'https',
				port: 443,
				path: '/ts-1',
			},
			{
				host: 'www.noir.changeeng.org',
				protocol: 'https',
				port: 443,
				path: '/ts-2',
			},
		],
	},
	plum: {
		apiKey: demoApiKey,
		nearestNode: {
			host: 'www.plum.changeeng.org',
			protocol: 'https',
			port: 443,
			path: '/ts',
		},
		nodes: [
			{
				host: 'www.plum.changeeng.org',
				protocol: 'https',
				port: 443,
				path: '/ts-0',
			},
			{
				host: 'www.plum.changeeng.org',
				protocol: 'https',
				port: 443,
				path: '/ts-1',
			},
			{
				host: 'www.plum.changeeng.org',
				protocol: 'https',
				port: 443,
				path: '/ts-2',
			},
		],
	},
	teal: {
		apiKey: demoApiKey,
		nearestNode: {
			host: 'www.teal.changeeng.org',
			protocol: 'https',
			port: 443,
			path: '/ts',
		},
		nodes: [
			{
				host: 'www.teal.changeeng.org',
				protocol: 'https',
				port: 443,
				path: '/ts-0',
			},
			{
				host: 'www.teal.changeeng.org',
				protocol: 'https',
				port: 443,
				path: '/ts-1',
			},
			{
				host: 'www.teal.changeeng.org',
				protocol: 'https',
				port: 443,
				path: '/ts-2',
			},
		],
	},
	vert: {
		apiKey: demoApiKey,
		nearestNode: {
			host: 'www.vert.changeeng.org',
			protocol: 'https',
			port: 443,
			path: '/ts',
		},
		nodes: [
			{
				host: 'www.vert.changeeng.org',
				protocol: 'https',
				port: 443,
				path: '/ts-0',
			},
			{
				host: 'www.vert.changeeng.org',
				protocol: 'https',
				port: 443,
				path: '/ts-1',
			},
			{
				host: 'www.vert.changeeng.org',
				protocol: 'https',
				port: 443,
				path: '/ts-2',
			},
		],
	},
	opal: {
		apiKey: demoApiKey,
		nearestNode: {
			host: 'www.opal.changeeng.org',
			protocol: 'https',
			port: 443,
			path: '/ts',
		},
		nodes: [
			{
				host: 'www.opal.changeeng.org',
				protocol: 'https',
				port: 443,
				path: '/ts-0',
			},
			{
				host: 'www.opal.changeeng.org',
				protocol: 'https',
				port: 443,
				path: '/ts-1',
			},
			{
				host: 'www.opal.changeeng.org',
				protocol: 'https',
				port: 443,
				path: '/ts-2',
			},
		],
	},

	// See: https://github.com/typesense/typesense-instantsearch-adapter?tab=readme-ov-file#with-instantsearchjs
	development: {
		apiKey: 'xyz',
		nodes: [
			{
				host: 'change-typesense',
				protocol: 'http',
				port: 8108,
				path: '',
			},
		],
	},
	demo: {
		apiKey: demoApiKey,
		nearestNode: {
			host: 'www.demo.changeeng.org',
			protocol: 'https',
			port: 443,
			path: '/ts',
		},
		nodes: [
			{
				host: 'www.demo.changeeng.org',
				protocol: 'https',
				port: 443,
				path: '/ts-0',
			},
			{
				host: 'www.demo.changeeng.org',
				protocol: 'https',
				port: 443,
				path: '/ts-1',
			},
			{
				host: 'www.demo.changeeng.org',
				protocol: 'https',
				port: 443,
				path: '/ts-2',
			},
		],
	},
};
